import React from 'react';
import { useSelector } from 'react-redux';

import { Category } from '../../SearchTypes';
import { getCategoriesForContentType as getCategoriesForContentTypeAudio } from '../../entities/AudioSearchFilterOptions';
import { getCategoriesForContentType as getCategoriesForContentTypeImages } from '../../entities/ImageSearchFilterOptions';
import { getCategoriesForContentType as getCategoriesForContentTypeVideo } from '../../entities/VideoSearchFilterOptions';
import {
  selectContentType,
  selectContentClass,
} from '../../selectors/searchSelectors';
import { searchOptionsToLocation } from '../../utils/searchOptionsToLocation';

const getCategoriesForContentType = {
  video: getCategoriesForContentTypeVideo,
  image: getCategoriesForContentTypeImages,
  audio: getCategoriesForContentTypeAudio,
};

function CategoryLinks() {
  const contentClass = useSelector(selectContentClass);
  const contentType = useSelector(selectContentType);
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  const categories: Category[] =
    getCategoriesForContentType[contentClass]?.(contentType) ?? [];

  return (
    !!categories?.length &&
    !contentType.includes('all-') && (
      <div>
        <h2 className="VideoRelatedSearchTerms-header text-sm font-normal sans text-gray-650">
          Categories in {contentType}
        </h2>
        <div className="overflow-hidden flex py-2 overflow-x-scroll scrollbar-hidden whitespace-nowrap">
          {categories.map(
            (category: Category) =>
              category?.categoryId && (
                <div className="pr-2" key={category?.label}>
                  <a
                    href={
                      searchOptionsToLocation({
                        contentClass,
                        contentType,
                        categories: category?.value,
                        ...(category?.audioCategory && {
                          [category.audioCategory]: [category?.categoryId],
                        }),
                      })?.url
                    }
                    className="videoRelatedSearchTerms-term text-base text-gray-900 no-underline bg-gray-100 border border-gray-850 rounded-full py-2 px-6"
                  >
                    {category?.label}
                  </a>
                </div>
              )
          )}
        </div>
      </div>
    )
  );
}

export default CategoryLinks;
