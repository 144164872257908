import React from 'react';
import { useSelector } from 'react-redux';

import { isEqual } from '../../../../common/utils';
import { selectNavSubCategoriesForUrl } from '../../../../ui/UiSelectors';
import { selectContentType, selectUrl } from '../../selectors/searchSelectors';
import CategoryLinks from './CategoryLinks';

function InternalSearchLinksSEO() {
  const mediaNavOptions = {
    footage: 'stock-footage',
    'motion-backgrounds': 'animated-backgrounds',
    templates: 'Templates',
  };

  const contentType = useSelector(selectContentType);

  const url = useSelector(selectUrl);
  const selectCategory = url.split(/[\/\?]/g)[2];

  const category = selectCategory
    .replace(/-/g, ' ')
    .split(' ')
    .map((word) =>
      word.toLowerCase() === 'and'
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(' ');

  const selectedSubcategories = useSelector(
    selectNavSubCategoriesForUrl(mediaNavOptions[contentType], selectCategory),
    isEqual
  );

  if (!selectedSubcategories?.length) {
    return <CategoryLinks />;
  }

  return (
    <div>
      <h2 className="VideoRelatedSearchTerms-header text-sm font-normal sans text-gray-650">
        More subcategories in {category}
      </h2>
      <div className="overflow-hidden flex py-2 overflow-x-scroll scrollbar-hidden whitespace-nowrap">
        {selectedSubcategories.map(({ title, url }) => (
          <div className="pr-2" key={title}>
            <a
              href={url}
              className="videoRelatedSearchTerms-term text-base text-gray-900 no-underline bg-gray-100 border border-gray-850 rounded-full py-2 px-6"
            >
              {title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InternalSearchLinksSEO;
