import React from 'react';
import { twMerge } from 'tailwind-merge';

import { MagnifyingGlass } from '@videoblocks/react-icons';

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import VideoSiteConstants from '../../../../common/SiteConstants/VideoSiteConstants';

import './RelatedSearchTerms.less';

type Props = {
  keyword: string;
  url: string;
  onSearchTermClicked: (e: React.MouseEvent, keyword: string) => void;
  roundedDesign: boolean;
  showIcon?: boolean;
  isNewSearchUi?: boolean;
};

function RelatedSearchItem({
  onSearchTermClicked,
  url,
  keyword,
  roundedDesign,
  showIcon = true,
  isNewSearchUi,
}: Props) {
  const searchRoute = SiteConstants.getInstance().getSearchRoute();
  const encodedKeyword = encodeURIComponent(keyword).replace(
    /(%20|%2b|%2520| )/g,
    '-'
  );

  let searchUrl = searchRoute + '/' + encodedKeyword;
  const isVideoSite = searchRoute === new VideoSiteConstants().getSearchRoute();
  if (isVideoSite) {
    searchUrl = url.replace(/(%20|%2b|%2520| )/g, '-');
  }
  const baseClassName = isNewSearchUi
    ? 'relatedSearchTerms-term m-1 border border-solid border-gray-400 rounded text-gray-600 cursor-pointer flex-shrink-0 px-4 py-2 transition duration-200 ease-in-out focus:outline-none hover:bg-gray-100 items-center no-underline h-fit'
    : 'relatedSearchTerms-term border border-solid border-gray-400 rounded text-gray-600 cursor-pointer flex-shrink-0 mr-2 px-2 py-1 transition duration-200 ease-in-out focus:outline-none hover:bg-gra\n' +
      'y-100 items-center no-underline';

  const baseRoundedClassName = isNewSearchUi
    ? 'flex align-center rounded-full border-gray-600 py-2 px-4'
    : 'flex align-center rounded-full border-gray-600 mb-3 py-2 px-3';

  return (
    <a
      href={searchUrl}
      className={twMerge(baseClassName, roundedDesign && baseRoundedClassName)}
      data-testid="relatedSearchTerms-term"
      onClick={(e) => onSearchTermClicked(e, keyword)}
      title={`Search for ${keyword}`}
    >
      {showIcon && <MagnifyingGlass className="w-5 h-5" fill="#737E8C" />}
      {keyword}
    </a>
  );
}

export default RelatedSearchItem;
